import {Box, Text} from '@mantine/core';
import type {FC} from 'react';
import {MdWarning} from 'react-icons/md';
import {parseCookies} from 'nookies';
import {useUsersMe} from '../../core/repositories/usersMeRepository';
import {validateCookie} from '../../core/usecases/auth/checkOauthTokenExistence';
import {CommonLink} from './CommonLink';

export const EmailConfirmNoticeForPC: FC = () => {
  const cookies = parseCookies();
  const {isValid} = validateCookie(cookies);

  const {data: usersMe} = useUsersMe({
    config: {enabled: isValid},
  });

  return usersMe?.ok && !usersMe.value.isEmailConfirmed ? (
    <Box className="mb-[10px] flex w-full h-[53px] items-center justify-center bg-background-4 text-[22px] font-bold">
      <MdWarning size={24} className="text-notification-1" />
      <Text className="mr-2">
        いつも使うメールアドレスが認証されていません。
      </Text>
      <CommonLink href="/mypage/profile" className="text-link-1 underline">
        認証について
      </CommonLink>
    </Box>
  ) : null;
};
