import {
  type UseInfiniteQueryResult,
  useInfiniteQuery,
  useQuery,
  type UseQueryOptions,
  useMutation,
} from '@tanstack/react-query';
import {useState} from 'react';
import {z} from 'zod';
import {InvalidTokenError, type AppError} from '@packages/utils';
import {parseCookies} from 'nookies';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {
  GetNotificationsResponseSchema,
  type GetNotificationsResponseType,
  responseSchema,
} from '../entities/notifications/schema';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

type UseNotificationsCountsArg = {
  config?: UseQueryOptions<GetNotificationsCountsResponse>;
} & Omit<GetNotificationsCountsArg, 'cookies'>;

export type GetNotificationsCountsResponse = z.infer<typeof responseSchema>;

export type GetNotificationsCountsArg = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getNotificationsCounts = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetNotificationsCountsArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/notifications/counts`,
      responseSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    request,
    authRequired: true,
  });

  return result;
};

export const useNotificationsCounts = ({
  config,
  ...args
}: UseNotificationsCountsArg) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  const [favoritedCompany, setFavoritedCompany] = useState(0);

  const {isLoading} = useQuery<GetNotificationsCountsResponse>(
    ['useNotificationsCounts', args],
    async () => {
      const cookies = parseCookies();
      const result = await getNotificationsCounts({cookies, ...args});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      if (!result.ok) {
        throw new Error(result.error);
      }

      return result.value;
    },
    {
      ...config,
      onSuccess(data) {
        setFavoritedCompany(data.favoritedCompany);
      },
    },
  );

  return {
    counts: {
      favoritedCompany,
    },
    isLoading,
  };
};

export type Notification = {
  id: number;
  title: string;
  body: string;
  detailURL: string;
  isUnread: boolean;
  eventType: string;
  createdAt: string;
};

type GetNotificationsArg = {
  page?: number;
  cookies: Partial<Record<string, string>>;
  forAllUsers: boolean;
  categories?: string[];
  withoutCategories?: string[];
  limit?: number;
  version?: string;
};

export type NotificationResponse = {
  totalCount: number;
  nextPage: number;
  notifications: Notification[];
};

// ログイントップ画面通知取得用
export const getLoggedInTopNotifications = async ({
  page = 1,
  cookies,
  limit = 20,
  forAllUsers,
  categories = [],
  withoutCategories = [],
  version = DEFAULT_API_VERSION,
}: GetNotificationsArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    const params = {
      page,
      limit,
      forAllUsers,
      categories,
      withoutCategories,
    };
    const path = createPathWithQuery(
      `/${version}/notifications/list`,
      params,
      'after',
    );
    return defaultClient.get(path, GetNotificationsResponseSchema);
  };

  const result = await sendApiRequest({
    cookies,
    request,
    authRequired: true,
  });

  return result;
};

type PutNotificationOpenArg = {
  notificationId: number;
  cookies: Partial<Record<string, string>>;
  allPast?: boolean;
  version?: string;
};

const PutNotificationOpen = z.object({});

// 指定IDのみの既読処理 PUT /notifications/{notificationId}/open
// 全ての通知一覧の既読処理 PUT /notifications/{notificationId}/open?allPast=true (notificationIdは最新の通知IDを指定)
export const putReadNotification = async ({
  notificationId,
  cookies,
  allPast = false,
  version = DEFAULT_API_VERSION,
}: PutNotificationOpenArg) => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.put(
      `/${version}/notifications/${notificationId}/open`,
      PutNotificationOpen,
      {
        allPast,
      },
    );
  };

  const result = await sendApiRequest({
    cookies,
    request,
    authRequired: true,
  });

  return result;
};

type UsePutReadNotificationArg = {
  version?: string;
  onMutateFnc?: () => void;
  onSuccessFnc?: () => void;
  onErrorFnc?: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePutReadNotification = ({
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePutReadNotificationArg) => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useMutation({
    async mutationFn({
      notificationId,
      allPast,
      version,
    }: Omit<PutNotificationOpenArg, 'cookies'>) {
      const cookies = parseCookies();
      const result = await putReadNotification({
        cookies,
        notificationId,
        allPast,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
    onMutate() {
      onMutateFnc?.();
    },
    onSuccess() {
      onSuccessFnc?.();
    },
    onError(error: AppError) {
      onErrorFnc?.(error);
    },
    onSettled() {
      onSettledFnc?.();
    },
  });
};

type UseNotificationsArg = {
  config?: UseQueryOptions<GetNotificationsResponseType>;
} & Omit<GetNotificationsArg, 'cookies'>;

export const useInfiniteNotifications = ({
  config,
  ...args
}: UseNotificationsArg): UseInfiniteQueryResult<
  GetNotificationsResponseType,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery(
    ['notifications'],
    async ({pageParam = 1}) => {
      const cookies = parseCookies();
      const result = await getLoggedInTopNotifications({
        ...args,
        cookies,
        page: pageParam,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      if (!result.ok) {
        throw new Error(result.error);
      }

      return result.value;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage ?? 0,
    },
  );
};

export const useInfiniteFollowCompanyNotifications = ({
  config,
  ...args
}: UseNotificationsArg): UseInfiniteQueryResult<
  GetNotificationsResponseType,
  AppError
> => {
  const {updateIsSessionExpired} = useIsSessionExpired();

  return useInfiniteQuery(
    ['followCompanyNotifications'],
    async ({pageParam = 1}) => {
      const cookies = parseCookies();
      const result = await getLoggedInTopNotifications({
        ...args,
        cookies,
        page: pageParam,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      if (!result.ok) {
        throw new Error(result.error);
      }

      return result.value;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage ?? 0,
    },
  );
};
